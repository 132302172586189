













import { defineComponent, PropType } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import MagazineCard, {
  MagazineCard as MagazineCardType,
} from '@/components/magazine/Card.vue'

export default defineComponent({
  name: 'MagazineGrid',
  components: {
    MagazineCard,
  },
  props: {
    items: {
      type: Array as PropType<MagazineCardType[]>,
      required: false,
    },
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
